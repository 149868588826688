// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea.Feedback{
    height: 150px;
    margin: 10px 0px;
    padding: 5px 10px 10px 10px;
    resize: none;
    font-size: 16px;

}`, "",{"version":3,"sources":["webpack://./src/CheckDetail.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,gBAAgB;IAChB,2BAA2B;IAC3B,YAAY;IACZ,eAAe;;AAEnB","sourcesContent":["textarea.Feedback{\n    height: 150px;\n    margin: 10px 0px;\n    padding: 5px 10px 10px 10px;\n    resize: none;\n    font-size: 16px;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
